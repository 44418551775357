import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import shortid from "shortid"
import { graphql, Link } from "gatsby"
import Slider from "react-slick"
import { LocaleContext } from "../contexts/LocaleContext"
import SEO from "../components/SEO.js"

import "slick-carousel/slick/slick.css"

const Index = ({
  data: { homepage },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const meta = {
    title: homepage.data.meta_title,
    description: homepage.data.meta_description,
    keywords: homepage.data.meta_keywords,
    author: "Emil Mureșan",
  }

  const schema = {
    "@graph": [],
  }
  const ogp = []

  ogp.push({
    property: "og:image",
    content: homepage.data.slider[0].image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: homepage.data.slider[0].image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: homepage.data.slider[0].image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: homepage.data.slider[0].image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const settingsHorizontal = {
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 5000,
    dots: false,
    draggable: true,
    swipe: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
  }

  return (
    <div id="home">
      <SEO
        schema={JSON.stringify(schema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
      />
      <Slider {...settingsHorizontal}>
        {homepage.data.slider.map(slide => (
          <Img
            key={shortid.generate()}
            alt={slide.image.alt}
            fluid={slide.image.fluid}
            className="full-image"
          />
        ))}
      </Slider>

      <div className="vcard">
        <h1>EMIL MUREȘAN</h1>
        <p>{i18n.headline.toUpperCase()}</p>
        <span className="contact">
          <a href="tel:40751101579">+40 751 101 579</a> {`// `}
          <a
            href="https://goo.gl/maps/baaqB7qFgeW5WkNa7"
            target="_blank"
            rel="noreferrer"
          >
            {i18n.addressLine}
          </a>
        </span>
        <span className="contact">
          <a href="mailto:contact@turistintransilvania.com">
            contact@turistintransilvania.com
          </a>
        </span>
      </div>

      <div className="navigation">
        {homepage.data.menu.map((item, idx) => (
          <span key={shortid.generate()}>
            <Link to={item.path}>{item.name.toUpperCase()}</Link>
            <span>{idx < homepage.data.menu.length - 1 ? " // " : ""}</span>
          </span>
        ))}
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        slider {
          image {
            alt
            dimensions {
              height
              width
            }
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        menu {
          path
          name
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Index
